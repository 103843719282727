import React from "react"
import { IconContext } from "react-icons"
import { FaLine, FaFacebookSquare } from "react-icons/fa"

export default function Footer() {
  return (
    <footer className="footer mt-3 pt-3 pb-2">
      <div className="container">
        <div className="site-contact text-center pb-3">
          <span className="pr-5">
            <IconContext.Provider
              value={{ color: "#00b900", size: "2em", className: "mr-1" }}
            >
              <FaLine />
            </IconContext.Provider>
            <a
              href="https://line.me/R/ti/p/%40ykz5170o"
              target="_blank"
              rel="noopener noreferrer"
            >
              @toskysoft
            </a>
          </span>
          <span>
            <IconContext.Provider
              value={{ color: "#4468b2", size: "2em", className: "mr-1" }}
            >
              <FaFacebookSquare />
            </IconContext.Provider>
            <a
              href="https://www.facebook.com/toskysoft"
              target="_blank"
              rel="noopener noreferrer"
            >
              toSKYsoft
            </a>
          </span>
        </div>
        <div className="site-info">
          &copy;{new Date().getFullYear()} Toskysoft.com. All rights reserved.
        </div>
      </div>
    </footer>
  )
}
