/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Navbar from "./navbar"
import Footer from "./footer"
import "../assets/styles/bootstrap.min.css"
import "../assets/styles/index.css"

const Layout = ({ children }) => {
  return (
    <>
      <div id="page" className="site">
        <Navbar />

        <div id="content" className="site-content">
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
