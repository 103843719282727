import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "../assets/images/logo-toskysoft-xs.png"

export default class Navbar extends Component {
  state = {
    navbarWrapClass: "navigation-wrap bg-light start-header",
    navbarOpen: false,
    navItemHover: false,
    css: "collapse navbar-collapse",
  }

  navbarHandler = () => {
    if (this.state.navbarOpen === true) {
      this.setState({ navbarOpen: false, css: "collapse navbar-collapse" })
    } else {
      this.setState({ navbarOpen: true, css: "collapse navbar-collapse show" })
    }
  }

  navItemHanler = () => {
    this.setState(prevState => ({
      navItemHover: !prevState.navItemHover,
    }))
  }

  doNothingHandler = e => {
    e.preventDefault()
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollHandler)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler)
  }

  scrollHandler = event => {
    let scroll = window.scrollY

    if (scroll >= 10) {
      this.setState({
        navbarWrapClass: "navigation-wrap bg-light start-header scroll-on",
      })
    } else {
      this.setState({
        navbarWrapClass: "navigation-wrap bg-light start-header start-style",
      })
    }
  }

  render() {
    return (
      <div className={this.state.navbarWrapClass}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-md navbar-light">
                <Link to="/" className="navbar-brand">
                  <img src={logo} alt="Logo toskysoft" />
                </Link>

                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.navbarHandler}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className={this.state.css} id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto py-4 py-md-0">
                    <li
                      className={
                        this.state.navItemHover
                          ? "nav-item pl-4 pl-md-0 ml-0 ml-md-4 show"
                          : "nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                      }
                      onMouseEnter={this.navItemHanler}
                      onMouseLeave={this.navItemHanler}
                      onClick={this.navItemCancelHover}
                    >
                      <a
                        href="#nothing"
                        onClick={this.doNothingHandler}
                        className="nav-link dropdown-toggle"
                        style={{ cursor: "pointer" }}
                      >
                        บริการของเรา
                      </a>
                      <div className="dropdown-menu">
                        <Link
                          to="/our-services/website"
                          className="dropdown-item"
                        >
                          รับทำเว็บไซต์ทุกประเภท
                        </Link>
                        <Link
                          to="/our-services/fix-facebook-page-advertising-account-closed"
                          className="dropdown-item"
                        >
                          แก้เพจ Facebook ถูกปิดบัญชีโฆษณา
                        </Link>
                        <Link
                          to="/our-services/fix-facebook-page-vision-disabled"
                          className="dropdown-item"
                        >
                          แก้เพจ Facebook ถูกปิดการมองเห็น
                        </Link>
                        <Link
                          to="/our-services/create-quality-facebook-page"
                          className="dropdown-item"
                        >
                          สร้างเพจ Facebook คุณภาพ
                        </Link>
                        <Link
                          to="/our-services/consult-facebook-page"
                          className="dropdown-item"
                        >
                          รับเป็นที่ปรึกษาเพจ Facebook
                        </Link>
                        <Link
                          to="/our-services/google-my-business"
                          className="dropdown-item"
                        >
                          รับทำ Google My Business
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                      <Link to="/blogs" className="nav-link">
                        บทความ
                      </Link>
                    </li>
                    <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                      <Link to="/faq" className="nav-link">
                        คำถามที่ถามบ่อย
                      </Link>
                    </li>
                    <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                      <Link to="/contact" className="nav-link">
                        ติดต่อเรา
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
